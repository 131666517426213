import React from "react"
import {rhythm, scale} from "../utils/typography";
import './AppHeader.scss';
import Logo from "./Logo";
import AppButton from "./AppButton";

export default function AppHeader() {
    return (<header className={'main'}>
        <div style={{
            paddingTop: `${rhythm(1)}`,
            marginBottom: `${rhythm(2)}`,
            display: 'flex',
            alignItems: 'center'

        }}>
            <Logo/>
            <AppButton
                style={{
                    marginLeft: 'auto'
                }}
                to={'https://www.cuptorulluirobert.ro/produse.html'}>
                Vezi produsele noastre
            </AppButton>
        </div>
    </header>);
}
