import React from "react"
import "./AppFooter.scss"

export default function AppFooter() {
    return (
        <footer className="main">
          <div className="copy">
            &copy; 2020 - <a href="https://www.cuptorulluirobert.ro/">Cuptorul lui Robert</a>
            <span className={'address'}>Str. Pictor Stefan Luchian 1A, Brasov</span>
          </div>

          <nav>
              <ul>
                  <li><a href="https://www.cuptorulluirobert.ro/">Acasa</a></li>
                  <li><a href="https://www.cuptorulluirobert.ro/produse.html">Produse</a></li>
                  <li><a href="https://www.cuptorulluirobert.ro/despre.html">Despre</a></li>
                  <li><a href="https://www.cuptorulluirobert.ro/contact.html">Contact</a></li>
              </ul>
          </nav>
        </footer>
    )
}
