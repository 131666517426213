import React from "react";
import logo from '../img/logo.svg';
import './Logo.scss';

export default function Logo() {
    return (
        <a className="logo" href="/">
            <img src={logo} alt={'Cuptorul lui Robert Blog'} />
        </a>
    )
}
