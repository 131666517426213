import React from "react"

import "../styles/general.scss"
import AppHeader from "./AppHeader";
import AppFooter from "./Appfooter";
import Splash from "./Splash";

export default function Layout(props) {
  return (
    <div className={'app'}>
      <Splash/>
      <AppHeader location={props.location} title={props.title} />
      <main>{props.children}</main>
      <AppFooter/>
    </div>
  )
}
