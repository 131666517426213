import React from "react"
import './Splash.scss';
import bgLeft from '../img/footer-left.png';
import bgRight from '../img/footer-right.png';
import rob from '../img/rob.png';

export default function Splash() {
  return (
    <section className="splash cpct">
      <div className="table">
        <img src={rob} className="main" alt="Cuptorul Lui Robert Tort"/>
        <img className="bg-left" src={bgLeft}/>
        <img className="bg-right" src={bgRight}/>
      </div>
    </section>
  );
}
